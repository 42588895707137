import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

/**
 * Verilen 'sonucData' dizisini tek bir hiyerarşik kök (root) objesine dönüştürür.
 */
function transformToHierarchy(sonucData) {
    const rootNode = {
        name: 'Sipariş Root',
        children: [],
        totalCost: 0,
        totalQuantity: 0,
        totalTime: 0, // Yeni: kök için de süre
    };

    sonucData.forEach((item) => {
        // StokHareketi altında bir düğüm:
        const stokNode = {
            name: item?.stokHareketi?.KartAdi || 'Bilinmeyen Stok',
            children: [],
            totalCost: 0,
            totalQuantity: 0,
            totalTime: 0, // Yeni
        };

        // 1) İş Emri varsa ekle:
        if (item.isEmri) {
            const isEmriNode = parseIsEmriRecursive(item.isEmri);
            stokNode.children.push(isEmriNode);
            stokNode.totalCost += isEmriNode.totalCost || 0;
            stokNode.totalQuantity += isEmriNode.totalQuantity || 0;
            stokNode.totalTime += isEmriNode.totalTime || 0; // Yeni
        }

        rootNode.children.push(stokNode);
        rootNode.totalCost += stokNode.totalCost;
        rootNode.totalQuantity += stokNode.totalQuantity;
        rootNode.totalTime += stokNode.totalTime; // Yeni
    });

    // Kök düğüme birim maliyet ekle (toplam miktar sıfır değilse)
    if (rootNode.totalQuantity > 0) {
        rootNode.unitCost = rootNode.totalCost / rootNode.totalQuantity;
    }

    return rootNode;
}

/**
 * Bir iş emrinin operasyonları + üretim planlarını ve alt iş emirlerini rekurzif olarak D3 formatına çevirir.
 */
function parseIsEmriRecursive(isEmriObj) {
    const emriID = isEmriObj.isEmri?.[0]?.ReceteAdi || 'Bilinmeyen İş Emri';

    // Operasyon ve plan sayıları
    const operationsCount = Array.isArray(isEmriObj.operasyonlar) ? isEmriObj.operasyonlar.length : 0;
    const plansCount = Array.isArray(isEmriObj.uretimPlanlari) ? isEmriObj.uretimPlanlari.length : 0;

    let statusSuffix = '';
    if (operationsCount > 0 && operationsCount === plansCount) {
        statusSuffix = ' (Üretilmiş)';
    } else if (plansCount > 0 && operationsCount === 0) {
        statusSuffix = ' (Üretilecek)';
    }

    // İş Emri ana düğüm adı
    const emriName = `İş Emri: ${emriID}${statusSuffix}`;
    const children = [];
    let totalCost = 0;
    let totalQuantity = 0;
    let totalTime = 0; // Yeni: iş emri toplam süresi

    // Operasyonları ekle
    if (operationsCount > 0) {
        let operationsTotalCost = 0;
        let operationsTotalQuantity = 0;
        let operationsTotalTime = 0; // Yeni

        const operasyonlarNode = {
            name: `Operasyonlar (${operationsCount})`,
            children: isEmriObj.operasyonlar.map((op) => {
                // Maliyet
                const miktar = parseFloat(op.Miktar) || 0;
                const birimMaliyet = parseFloat(op.BirimMaliyetFiyati) || 0;
                const operasyonMaliyet = miktar * birimMaliyet;

                // Süre (dakika veya saat bazlı olabilir; varsayılan toplam dakika)
                const hazirlik = parseFloat(op.HazirlikSure) || 0;
                const uretim = parseFloat(op.UretimSure) || 0;
                const duraklama = parseFloat(op.DuraklamaSure) || 0;
                const mola = parseFloat(op.MolaSure) || 0;
                const opTime = hazirlik + uretim + duraklama + mola;

                // Toplamlara ekle
                operationsTotalCost += operasyonMaliyet;
                operationsTotalQuantity += miktar;
                operationsTotalTime += opTime;

                return {
                    name: `${op.OperasyonAdi || 'Bilinmeyen Op.'} - ${op.OperasyonAciklama || ''}`,
                    details: {
                        type: 'operasyon',
                        sira: op.Sira,
                        durum: op.Durum,
                        istasyon: op.MakineAdi || 'Makine Yok',
                        miktar: miktar,
                        birimMaliyet: birimMaliyet,
                        toplamMaliyet: operasyonMaliyet,
                        // Yeni: Süre detayları
                        hazirlik: hazirlik,
                        uretim: uretim,
                        duraklama: duraklama,
                        mola: mola,
                        toplamSure: opTime,
                    },
                };
            }),
            totalCost: operationsTotalCost,
            totalQuantity: operationsTotalQuantity,
            totalTime: operationsTotalTime, // Yeni
        };

        // Operasyonlar düğümünün birim maliyeti ve birim süresi
        if (operationsTotalQuantity > 0) {
            operasyonlarNode.unitCost = operationsTotalCost / operationsTotalQuantity;
            operasyonlarNode.unitTime = operationsTotalTime / operationsTotalQuantity; // Yeni
        }

        const unitCostText = operasyonlarNode.unitCost
            ? ` - Birim Maliyet: ${formatCurrency(operasyonlarNode.unitCost)}`
            : '';
        const totalTimeText = operasyonlarNode.totalTime
            ? ` - Toplam Süre: ${formatMinutes(operasyonlarNode.totalTime)}`
            : '';
        const unitTimeText = operasyonlarNode.unitTime
            ? ` - Birim Süre: ${formatMinutes(operasyonlarNode.unitTime)}`
            : '';

        operasyonlarNode.name = `Operasyonlar (${operationsCount}) - Toplam: ${formatCurrency(
            operationsTotalCost
        )}${unitCostText}${totalTimeText}${unitTimeText}`;

        children.push(operasyonlarNode);
        totalCost += operationsTotalCost;
        totalQuantity += operationsTotalQuantity;
        totalTime += operationsTotalTime; // Yeni
    }

    // Üretim planları (Sadece kapalı=false) - Örnek:
    if (plansCount > 0) {
        const uretilecekPlanlar = isEmriObj.uretimPlanlari.filter((plan) => !plan.Kapali);
        if (uretilecekPlanlar.length > 0) {
            const uretimPlanlariNode = {
                name: `Üretim Planları (${uretilecekPlanlar.length})`,
                children: uretilecekPlanlar.map((plan) => parseUretimPlani(plan)),
                totalCost: 0,
                totalQuantity: 0,
                totalTime: 0,
            };
            children.push(uretimPlanlariNode);
        }
    }

    // Alt iş emirleri
    if (Array.isArray(isEmriObj.altIsEmirleri) && isEmriObj.altIsEmirleri.length > 0) {
        isEmriObj.altIsEmirleri.forEach((alt) => {
            const altEmriNode = parseIsEmriRecursive(alt);
            children.push(altEmriNode);
            totalCost += altEmriNode.totalCost || 0;
            totalQuantity += altEmriNode.totalQuantity || 0;
            totalTime += altEmriNode.totalTime || 0; // Yeni
        });
    }

    // Birim maliyet ve birim süre hesapla
    const unitCost = totalQuantity > 0 ? totalCost / totalQuantity : 0;
    const unitTime = totalQuantity > 0 ? totalTime / totalQuantity : 0;

    // İş emri adını güncelle (maliyet + süre)
    let emriNameWithCost = emriName;
    if (totalCost > 0) {
        emriNameWithCost += ` - Toplam Maliyet: ${formatCurrency(totalCost)}`;
        if (unitCost > 0) {
            emriNameWithCost += ` - Birim Maliyet: ${formatCurrency(unitCost)}`;
        }
    }
    if (totalTime > 0) {
        emriNameWithCost += ` - Toplam Süre: ${formatMinutes(totalTime)}`;
        if (unitTime > 0) {
            emriNameWithCost += ` - Birim Süre: ${formatMinutes(unitTime)}`;
        }
    }

    return {
        name: emriNameWithCost,
        children,
        totalCost,
        totalQuantity,
        totalTime, // Yeni
        unitCost,
        unitTime,
    };
}

/**
 * Tekil üretim planını D3 düğümüne dönüştüren örnek fonksiyon.
 */
function parseUretimPlani(planObj) {
    const planAdi = planObj.OperasyonAdi + ' - ' + (planObj.Tarih || 'Tarih Yok');
    const planName = `Plan: ${planAdi} (Üretilecek)`;

    return {
        name: planName,
        children: [],
        totalCost: 0,
        totalQuantity: 0,
        totalTime: 0,
    };
}

/**
 * Basit bir para formatı
 */
function formatCurrency(value) {
    return new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
    }).format(value);
}

/**
 * Dakika cinsinden süreyi "X saat Y dakika" veya benzeri gösterimle döndürür.
 * (Bu fonksiyonu dilediğiniz gibi sadeleştirebilirsiniz.)
 */
function formatMinutes(minutesValue) {
    const m = Math.round(minutesValue);
    const hours = Math.floor(m / 60);
    const mins = m % 60;
    if (hours === 0) {
        return `${mins} dk`;
    }
    return `${hours}sa ${mins}dk`;
}

// --------------------------------------------------
// FlowDiagram bileşeni
// --------------------------------------------------
function FlowDiagram({ data }) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        if (!data || data.length === 0) return;

        // 1) Hiyerarşik veriyi oluştur
        const hierarchyData = transformToHierarchy(data);

        // 2) Önceki çizimleri temizle
        d3.select(container).selectAll('*').remove();

        // 3) D3 hiyerarşi ve tree
        const width = 3600;
        const root = d3.hierarchy(hierarchyData);
        const dx = 75;
        const dy = width / (root.height + 1);

        const tree = d3
            .tree()
            .nodeSize([dx, dy])
            .separation((a, b) => (a.parent === b.parent ? 1 : 1.2));

        root.sort((a, b) => d3.ascending(a.data.name, b.data.name));
        tree(root);

        let x0 = Infinity;
        let x1 = -x0;
        root.each((d) => {
            if (d.x > x1) x1 = d.x;
            if (d.x < x0) x0 = d.x;
        });

        const height = x1 - x0 + dx * 2;

        const svg = d3
            .select(container)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .attr('viewBox', [-dy / 3, x0 - dx, width, height])
            .attr('style', 'max-width: 100%; height: auto; font: 12px sans-serif;');

        // Düğümlerin rengi
        const getNodeColor = (d) => {
            const nodeName = d.data.name || '';
            if (nodeName.startsWith('İş Emri:')) return '#e2725b';
            if (nodeName.startsWith('Operasyonlar')) return '#4caf50';
            if (nodeName.startsWith('Üretim Planları')) return '#ff9800';
            if (d.data.details?.type === 'operasyon') return '#81c784';
            if (nodeName === 'Sipariş Root') return '#a47864';
            return '#a47864';
        };

        // Bağlantı çizgileri
        svg.append('g')
            .attr('fill', 'none')
            .attr('stroke', '#999')
            .attr('stroke-opacity', 0.5)
            .attr('stroke-width', 1.5)
            .selectAll('path')
            .data(root.links())
            .join('path')
            .attr(
                'd',
                d3
                    .linkHorizontal()
                    .x((d) => d.y)
                    .y((d) => d.x)
            )
            .attr('stroke', (d) => getNodeColor(d.target));

        // Düğümler
        const node = svg
            .append('g')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-width', 3)
            .selectAll('g')
            .data(root.descendants())
            .join('g')
            .attr('transform', (d) => `translate(${d.y},${d.x})`);

        node.append('circle')
            .attr('fill', getNodeColor)
            .attr('r', (d) => (d.data.details?.type === 'operasyon' ? 2.5 : 3.5));

        // Metin etiketi
        node.append('text')
            .attr('dy', '0.31em')
            .attr('x', (d) => (d.children ? -8 : 8))
            .attr('text-anchor', (d) => (d.children ? 'end' : 'start'))
            .text((d) => {
                // Operasyon düğümü ise, masraf + süre detaylarını ekleyelim
                if (d.data.details?.type === 'operasyon') {
                    const { miktar, birimMaliyet, toplamMaliyet, toplamSure, sira } = d.data.details;

                    const miktarText = miktar ? ` Miktar: ${miktar}` : '';
                    const bmText = birimMaliyet ? ` BirimMaliyet: ${formatCurrency(birimMaliyet)}` : '';
                    const totalMText = toplamMaliyet ? ` ToplamMasraf: ${formatCurrency(toplamMaliyet)}` : '';
                    const sureText = toplamSure ? ` Süre: ${formatMinutes(toplamSure)}` : '';

                    return `${d.data.name} [Sıra: ${sira}] =>${miktarText}${bmText}${totalMText}${sureText}`;
                }
                return d.data.name;
            })
            .attr('stroke', 'white')
            .attr('stroke-width', 3)
            .attr('paint-order', 'stroke')
            .attr('font-size', (d) => (d.data.details?.type === 'operasyon' ? '10px' : '12px'))
            .attr('font-weight', '500');

        return () => {
            d3.select(container).selectAll('*').remove();
        };
    }, [data]);

    return (
        <div
            ref={containerRef}
            style={{
                width: '100%',
                height: 'auto',
                overflow: 'auto',
                padding: '20px',
                backgroundColor: '#fff',
            }}
        />
    );
}

export default FlowDiagram;
