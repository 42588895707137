import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

/**
 * StokHareketi -> alt isEmri -> operasyonlar -> altIsEmirleri
 * şeklinde veri yapınızı D3’ün beklediği hiyerarşik formata dönüştürür.
 */
function transformToHierarchy(sonucData) {
    // Tek bir root node:
    const rootNode = {
        name: 'Sipariş Root',
        children: [],
    };

    sonucData.forEach((satir) => {
        const stokName = satir?.stokHareketi?.KartAdi || 'Bilinmeyen Stok';
        const stokChildren = [];

        // 1) Stok node
        const stokNode = {
            name: stokName,
            children: stokChildren,
            details: {
                type: 'stok',
            },
        };

        // 2) isEmri varsa
        if (satir.isEmri) {
            const isEmriNode = parseIsEmriRecursive(satir.isEmri);
            stokChildren.push(isEmriNode);
        }

        rootNode.children.push(stokNode);
    });

    return rootNode;
}

/**
 * isEmri (içinde isEmri[], operasyonlar, altIsEmirleri vb.) => D3 node
 */
function parseIsEmriRecursive(isEmriObj) {
    const isEmriItem = isEmriObj.isEmri?.[0];
    const name = isEmriItem?.ReceteAdi || 'Bilinmeyen İş Emri';
    const children = [];

    // 1) Operasyonları ekle
    if (Array.isArray(isEmriObj.operasyonlar) && isEmriObj.operasyonlar.length > 0) {
        const opNode = {
            name: `Operasyonlar (${isEmriObj.operasyonlar.length})`,
            children: isEmriObj.operasyonlar.map((op) => ({
                name: op.OperasyonAdi || 'Bilinmeyen Operasyon',
                details: {
                    type: 'operasyon',
                    ...op,
                },
                children: [],
            })),
        };
        children.push(opNode);
    }

    // 2) Alt İş Emirleri
    if (Array.isArray(isEmriObj.altIsEmirleri) && isEmriObj.altIsEmirleri.length > 0) {
        isEmriObj.altIsEmirleri.forEach((altIsEmri) => {
            const altNode = parseIsEmriRecursive(altIsEmri);
            children.push(altNode);
        });
    }

    return {
        name: `İş Emri: ${name}`,
        children,
        details: {
            type: 'isemri',
            ...isEmriItem,
        },
    };
}

/**
 * Zoomable Sunburst Bileşeni
 */
function SunburstDiagram({ data }) {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!data || data.length === 0) return;
        const container = containerRef.current;

        // 1) Veri dönüştürme
        const hierarchyData = transformToHierarchy(data);

        // 2) D3 hiyerarşi oluşturalım (her node için .sum(() => 1) diyerek eşit değerler)
        let root = d3
            .hierarchy(hierarchyData)
            .sum(() => 1)
            .sort((a, b) => b.value - a.value);

        // partition => sunburst
        const partition = d3.partition().size([2 * Math.PI, root.height + 1]);

        root = partition(root);
        // Her node için current property’si
        root.each((d) => {
            d.current = d;
        });

        // Boyut
        const width = 928;
        const height = width;
        const radius = width / 8;

        // 3) Eski çizimleri temizle
        d3.select(container).selectAll('*').remove();

        // 4) Renk
        const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, root.children.length + 1));

        // 5) arc generator
        const arc = d3
            .arc()
            .startAngle((d) => d.x0)
            .endAngle((d) => d.x1)
            .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius((d) => d.y0 * radius)
            .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

        // 6) SVG
        const svg = d3
            .select(container)
            .append('svg')
            .attr('viewBox', [-width / 2, -height / 2, width, width])
            .style('font', '6px sans-serif');

        // 7) PATH (arc) ekle
        const gPath = svg.append('g');

        const path = gPath
            .selectAll('path')
            .data(root.descendants().slice(1)) // root hariç
            .join('path')
            .attr('fill', (d) => {
                let p = d;
                while (p.depth > 1) p = p.parent;
                return color(p.data.name);
            })
            .attr('fill-opacity', (d) => (arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0))
            .attr('pointer-events', (d) => (arcVisible(d.current) ? 'auto' : 'none'))
            .attr('d', (d) => arc(d.current))
            // Zoom için tıklama
            .on('click', (event, d) => {
                if (d.children) clicked(event, d);
            })
            .style('cursor', (d) => (d.children ? 'pointer' : 'default'));

        // Tooltip veya title
        const format = d3.format(',d');
        path.append('title').text(
            (d) =>
                d
                    .ancestors()
                    .map((x) => x.data.name)
                    .reverse()
                    .join('/') + `\n${format(d.value)}`
        );

        // 8) Etiketler
        const gLabel = svg
            .append('g')
            .attr('pointer-events', 'none')
            .attr('text-anchor', 'middle')
            .style('user-select', 'none');

        const label = gLabel
            .selectAll('text')
            .data(root.descendants().slice(1))
            .join('text')
            .attr('dy', '0.35em')
            .attr('fill-opacity', (d) => +labelVisible(d.current))
            .attr('transform', (d) => labelTransform(d.current))
            .text((d) => d.data.name);

        // 9) Parent circle (kök’e geri dönmek için)
        const parentCircle = svg
            .append('circle')
            .datum(root)
            .attr('r', radius)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .attr('cursor', 'pointer')
            .on('click', (event, d) => clicked(event, d));

        // Tıklandığında zoom yapma
        function clicked(event, p) {
            parentCircle.datum(p.parent || root);

            root.each((d) => {
                d.target = {
                    x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                    x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                    y0: Math.max(0, d.y0 - p.depth),
                    y1: Math.max(0, d.y1 - p.depth),
                };
            });

            const t = svg.transition().duration(event.altKey ? 2500 : 750);

            path.transition(t)
                .tween('data', (d) => {
                    const i = d3.interpolate(d.current, d.target);
                    return (t) => (d.current = i(t));
                })
                .filter(function (d) {
                    return +this.getAttribute('fill-opacity') || arcVisible(d.target);
                })
                .attr('fill-opacity', (d) => (arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0))
                .attr('pointer-events', (d) => (arcVisible(d.target) ? 'auto' : 'none'))
                .attrTween('d', (d) => () => arc(d.current));

            label
                .filter(function (d) {
                    return +this.getAttribute('fill-opacity') || labelVisible(d.target);
                })
                .transition(t)
                .attr('fill-opacity', (d) => +labelVisible(d.target))
                .attrTween('transform', (d) => () => labelTransform(d.current));
        }

        // Görünürlük fonksiyonları
        function arcVisible(d) {
            // Örneğin 3. seviye altını göstermeyecek (isteğe göre)
            return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
        }

        function labelVisible(d) {
            return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
        }

        function labelTransform(d) {
            const x = ((d.x0 + d.x1) / 2) * (180 / Math.PI);
            const y = ((d.y0 + d.y1) / 2) * radius;
            return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
        }

        // Temizleme
        return () => {
            d3.select(container).selectAll('*').remove();
        };
    }, [data]);

    return <div ref={containerRef} style={{ width: '100%', height: 'auto', overflow: 'auto' }} />;
}

export default SunburstDiagram;
