import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Aaro } from 'aaro';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import AaroLoadingScreen from 'components/Loading';
import FlowDiagram from './FlowDiagram';
import FlowDiagramAgac from './FlowDiagramAgac';
import ZoomableSunBurst from './ZoomableSunBurst';
import ReactFlow, { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Grid } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MDButton from 'components/MDButton';
/**
 * Belirtilen süre kadar beklemek için delay fonksiyonu.
 */
function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Stok hareketlerini sıralı olarak çeken fonksiyon
 */
async function fetchSipStokHareketleri(dekontID, aaroInstance, addLog) {
    let page = 1;
    let hasNext = true;
    const allRecords = [];

    while (hasNext) {
        try {
            addLog(`SipStokHareketleri API - Sayfa: ${page}`);
            const response = await aaroInstance.get('SipStokHareketleri', {
                DekontID: dekontID,
                Sayfa: page,
                SayfaSatirSayisi: 100,
            });

            const data = response.data;
            if (data?.Model && Array.isArray(data.Model)) {
                allRecords.push(...data.Model);
            }
            hasNext = data?.SayfalandirmaBilgisi?.SonrakiSayfaVarMi || false;
            page++;

            // 201 ms gecikme
            await delay(201);
        } catch (error) {
            addLog(`SipStokHareketleri API hatası: ${error.message}`);
            break;
        }
    }

    return allRecords;
}

/**
 * İş emrini ve alt iş emirlerini sıralı olarak çeken rekürsif fonksiyon
 */
async function fetchIsEmriRecursive(isEmriID, aaroInstance, addLog, fetchedIDs = new Set()) {
    if (fetchedIDs.has(isEmriID)) {
        return null;
    }
    fetchedIDs.add(isEmriID);

    let isEmri = null;
    let operasyonlar = [];
    let altIsEmirleri = [];
    let uretimPlanlari = [];

    // 1) Ana İş Emri
    try {
        addLog(`İş Emri çekiliyor: ${isEmriID}`);
        const respIsEmri = await aaroInstance.get('UrIsEmri', {
            IsEmriID: isEmriID,
        });
        const data = respIsEmri.data;
        if (!data.Model) {
            addLog(`İş Emri ${isEmriID} bulunamadı!`);
            return null;
        }
        isEmri = data.Model;
        addLog(`İş Emri ${isEmriID} alındı.`);
        await delay(201);
    } catch (err) {
        addLog(`İş Emri ${isEmriID} getirilemedi: ${err.message}`);
        return null;
    }

    // 2) Operasyonlar (UrAkisOperasyonMamul)
    try {
        const respOperasyonlar = await aaroInstance.get('UrAkisOperasyonMamul', {
            IsEmriID: isEmriID,
        });
        if (respOperasyonlar.data?.Model && Array.isArray(respOperasyonlar.data.Model)) {
            operasyonlar = respOperasyonlar.data.Model;
            addLog(`${isEmriID} için ${operasyonlar.length} operasyon alındı.`);
        }
        await delay(201);
    } catch (err) {
        addLog(`Operasyonlar ${isEmriID} getirilemedi: ${err.message}`);
    }

    // 3) Üretim Planları (TumIsEmriPlan)
    try {
        const respOp = await aaroInstance.get('UrIsEmriPlan/TumIsEmriPlan', {
            IsEmriID: isEmriID,
        });
        if (respOp.data?.Model && Array.isArray(respOp.data.Model)) {
            uretimPlanlari = respOp.data.Model;
        }
        await delay(201);
    } catch (err) {
        addLog(`İş Emri Planları ${isEmriID} getirilemedi: ${err.message}`);
    }

    // 4) Alt İş Emirleri (rekürsif)
    try {
        const respAlt = await aaroInstance.get('UrIsEmri/AltIsEmri', {
            IsEmriID: isEmriID,
        });
        await delay(201);

        if (respAlt.data?.Model && Array.isArray(respAlt.data.Model)) {
            // Önemli değişiklik: paralel yerine sıralı (for...of) işleme
            for (const alt of respAlt.data.Model) {
                const child = await fetchIsEmriRecursive(alt.IsEmriID, aaroInstance, addLog, fetchedIDs);
                if (child) {
                    altIsEmirleri.push(child);
                }
            }
        }
    } catch (err) {
        addLog(`Alt İş Emirleri ${isEmriID} getirilemedi: ${err.message}`);
    }

    return { isEmri, operasyonlar, uretimPlanlari, altIsEmirleri };
}

/**
 * Tüm stok hareketleri için iş emirlerini sıralı olarak çeken fonksiyon
 */
async function fetchAllIsEmriData(stokHareketleri, aaroInstance, addLog) {
    const results = [];
    const fetchedIDs = new Set();

    // Kaç tane iş emri ID'si var sayalım
    const isEmriIDs = stokHareketleri.filter((hareket) => hareket.IsEmriID).map((hareket) => hareket.IsEmriID);

    addLog(`İşlenecek toplam ${isEmriIDs.length} adet iş emri bulundu.`);

    let islemSayisi = 0;

    // Sıralı işleme için for...of kullanılıyor
    for (const hareket of stokHareketleri) {
        if (!hareket.IsEmriID) continue;

        islemSayisi++;
        addLog(`İşEmriID: ${hareket.IsEmriID} alınıyor... (${islemSayisi}/${isEmriIDs.length})`);

        try {
            // Her işlemden önce biraz daha uzun bekleme ekleyelim
            await delay(500); // API'ye nefes aldıralım

            const emriObj = await fetchIsEmriRecursive(hareket.IsEmriID, aaroInstance, addLog, fetchedIDs);
            if (emriObj) {
                results.push({ stokHareketi: hareket, isEmri: emriObj });
                addLog(`✓ İşEmriID: ${hareket.IsEmriID} başarıyla işlendi. (${results.length} sonuç hazır)`);
            } else {
                addLog(`✗ İşEmriID: ${hareket.IsEmriID} için veri alınamadı veya boş döndü.`);
            }
        } catch (err) {
            addLog(`✗ HATA: İşEmriID: ${hareket.IsEmriID} işlenirken hata: ${err.message}`);
        }

        // Her stok hareketi sonrası biraz daha uzun bekleme
        await delay(300);
    }

    addLog(`İşEmri verileri alındı. Toplam ${results.length} kayıt.`);
    return results;
}

function Siparis({ dekontID }) {
    const [logs, setLogs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [sonucData, setSonucData] = useState([]);
    const [openDialog, setOpenDialog] = useState({
        flow: false,
        tree: false,
        sunburst: false,
    });

    const addLog = (msg) => {
        // Başarılı işlemler için tik, hatalar için çarpı ekle
        let logMessage = msg;
        if (msg.includes('başarıyla') || msg.includes('alındı') || msg.includes('bulundu')) {
            logMessage = `✓ ${msg}`;
        } else if (msg.includes('HATA') || msg.includes('getirilemedi') || msg.includes('bulunamadı')) {
            logMessage = `✗ ${msg}`;
        }
        setLogs((prev) => [logMessage, ...prev]);
        console.log(logMessage);
    };

    // localStorage'dan user token al
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const aaro = new Aaro({
        baseUrl: 'https://erp2.aaro.com.tr',
        accessToken: user.aaroToken || '',
    });

    // useEffect ile veri çekme işlemi (React Query yerine)
    const [refetchTrigger, setRefetchTrigger] = useState(0);

    React.useEffect(() => {
        // dekontID varsa ve refetch tetiklenmiş ise veri çekmeyi başlat
        if (!dekontID) return;

        let isMounted = true; // Komponent unmount olursa state güncellemeyi engellemek için

        const fetchData = async () => {
            if (!isMounted) return;

            setIsLoading(true);
            setError(null);
            setSonucData([]); // Veri çekmeye başlarken mevcut veriyi temizle
            setLogs([]); // Her yeni çağrıda logları da temizleyelim

            try {
                // 1. Stok hareketlerini çek
                addLog(`DekontID: ${dekontID} => stok hareketleri çekiliyor...`);
                const stokHareketleri = await fetchSipStokHareketleri(dekontID, aaro, addLog);
                addLog(`Toplam ${stokHareketleri.length} adet stok hareketi bulundu.`);

                if (stokHareketleri.length === 0) {
                    if (isMounted) {
                        setIsLoading(false);
                        addLog(`Stok hareketi bulunamadı. İşlem tamamlandı.`);
                    }
                    return;
                }

                // 2. Tüm iş emirlerini sıralı olarak çek
                addLog(`İş emirlerini çekmeye başlıyorum...`);
                const sonuclar = await fetchAllIsEmriData(stokHareketleri, aaro, addLog);

                // 3. Sonuçları state'e kaydet
                if (isMounted) {
                    addLog(`Veri çekimi tamamlandı. Toplam ${sonuclar.length} kayıt işlendi.`);
                    setSonucData(sonuclar);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error('Veri çekme hatası:', err);
                if (isMounted) {
                    addLog(`HATA: ${err.message}`);
                    setError(err);
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            isMounted = false;
        };
    }, [dekontID, refetchTrigger]); // refetchTrigger değiştiğinde useEffect yeniden çalışır

    // Yeniden çekme işlemi için kullanılacak fonksiyon
    const refetch = () => {
        setRefetchTrigger((prev) => prev + 1);
    };

    // Loading / Error durumları
    {
        /* if (isLoading) {
        return <AaroLoadingScreen />;
    }   */
    }

    if (error) {
        return <MDTypography color="error">Error: {error.message}</MDTypography>;
    }

    const handleOpenDialog = (type) => {
        setOpenDialog((prev) => ({ ...prev, [type]: true }));
    };

    const handleCloseDialog = (type) => {
        setOpenDialog((prev) => ({ ...prev, [type]: false }));
    };

    // Sonuç ekranda
    return (
        <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium">
                Node Kodunun React Versiyonu (Aaro + No Params) - Sıralı Veri Çekme
            </MDTypography>

            {/* Log Ekranı */}
            <MDBox border="1px solid #ccc" borderRadius="lg" p={2} maxHeight={200} overflow="auto" bgcolor="white">
                {logs.map((log, idx) => (
                    <MDBox key={idx} mb={0.5} display="flex" alignItems="center">
                        {log.includes('✓') ? (
                            <TaskAltIcon sx={{ color: 'success.main', mr: 1, fontSize: 16 }} />
                        ) : log.includes('✗') ? (
                            <CloseIcon sx={{ color: 'error.main', mr: 1, fontSize: 16 }} />
                        ) : null}
                        <MDTypography variant="body2" color="text">
                            {log.replace('✓', '').replace('✗', '')}
                        </MDTypography>
                    </MDBox>
                ))}
            </MDBox>

            {/* Visualization Buttons */}
            {sonucData.length > 0 && (
                <Grid container spacing={2} mt={2}>
                    {/* Flow Diagram Button */}
                    <Grid item xs={12} md={4}>
                        <MDButton variant="outlined" color="info" fullWidth onClick={() => handleOpenDialog('flow')}>
                            Akış Diyagramını Görüntüle
                        </MDButton>
                        <Dialog
                            open={openDialog.flow}
                            onClose={() => handleCloseDialog('flow')}
                            maxWidth="xl"
                            fullWidth
                            PaperProps={{
                                sx: {
                                    height: '90vh',
                                    maxHeight: '90vh',
                                },
                            }}
                        >
                            <MDBox p={2} height="100%">
                                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                    <MDTypography variant="h6">Akış Diyagramı</MDTypography>
                                    <IconButton onClick={() => handleCloseDialog('flow')}>
                                        <CloseIcon />
                                    </IconButton>
                                </MDBox>
                                <MDBox height="calc(100% - 50px)">
                                    <FlowDiagram data={sonucData} height="100%" />
                                </MDBox>
                            </MDBox>
                        </Dialog>
                    </Grid>

                    {/* Tree Diagram Button */}
                    <Grid item xs={12} md={4}>
                        <MDButton variant="outlined" color="info" fullWidth onClick={() => handleOpenDialog('tree')}>
                            Ağaç Diyagramını Görüntüle
                        </MDButton>
                        <Dialog
                            open={openDialog.tree}
                            onClose={() => handleCloseDialog('tree')}
                            maxWidth="xl"
                            fullWidth
                            PaperProps={{
                                sx: {
                                    height: '90vh',
                                    maxHeight: '90vh',
                                },
                            }}
                        >
                            <MDBox p={2} height="100%">
                                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                    <MDTypography variant="h6">Ağaç Diyagramı</MDTypography>
                                    <IconButton onClick={() => handleCloseDialog('tree')}>
                                        <CloseIcon />
                                    </IconButton>
                                </MDBox>
                                <MDBox height="calc(100% - 50px)">
                                    <ReactFlowProvider>
                                        <FlowDiagramAgac data={sonucData} height="100%" />
                                    </ReactFlowProvider>
                                </MDBox>
                            </MDBox>
                        </Dialog>
                    </Grid>

                    {/* Sunburst Diagram Button */}
                    <Grid item xs={12} md={4}>
                        <MDButton
                            variant="outlined"
                            color="info"
                            fullWidth
                            onClick={() => handleOpenDialog('sunburst')}
                        >
                            Sunburst Diyagramını Görüntüle
                        </MDButton>
                        <Dialog
                            open={openDialog.sunburst}
                            onClose={() => handleCloseDialog('sunburst')}
                            maxWidth="xl"
                            fullWidth
                            PaperProps={{
                                sx: {
                                    height: '90vh',
                                    maxHeight: '90vh',
                                },
                            }}
                        >
                            <MDBox p={2} height="100%">
                                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                    <MDTypography variant="h6">Sunburst Diyagramı</MDTypography>
                                    <IconButton onClick={() => handleCloseDialog('sunburst')}>
                                        <CloseIcon />
                                    </IconButton>
                                </MDBox>
                                <MDBox height="calc(100% - 50px)">
                                    <ZoomableSunBurst data={sonucData} height="100%" />
                                </MDBox>
                            </MDBox>
                        </Dialog>
                    </Grid>
                </Grid>
            )}

            {/* Yeniden çekme butonu ve durum bilgisi */}
            <MDBox mt={2} display="flex" flexDirection="column" gap={1}>
                <button
                    onClick={() => refetch()}
                    disabled={isLoading}
                    style={{
                        padding: '8px 16px',
                        backgroundColor: '#1976d2',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: isLoading ? 'not-allowed' : 'pointer',
                    }}
                >
                    {isLoading ? 'Veri çekiliyor... Lütfen bekleyin' : 'Verileri Yeniden Çek'}
                </button>

                {isLoading && (
                    <div
                        style={{
                            marginTop: '10px',
                            padding: '8px',
                            backgroundColor: '#fff9c4',
                            border: '1px solid #ffd54f',
                            borderRadius: '4px',
                        }}
                    >
                        <strong>Bilgi:</strong> Veri çekimi devam ediyor. Bu işlem, veri miktarına bağlı olarak biraz
                        zaman alabilir. Tüm veriler tam olarak alınana kadar lütfen bekleyin. İşlem durumunu loglardan
                        takip edebilirsiniz.
                    </div>
                )}

                {sonucData.length > 0 && (
                    <div
                        style={{
                            marginTop: '10px',
                            padding: '8px',
                            backgroundColor: '#e8f5e9',
                            border: '1px solid #81c784',
                            borderRadius: '4px',
                        }}
                    >
                        <strong>Bilgi:</strong> Toplam {sonucData.length} kayıt başarıyla yüklendi ve görselleştirildi.
                    </div>
                )}
            </MDBox>
        </MDBox>
    );
}

export default Siparis;
