import React from 'react';

import MDBox from 'components/MDBox';
import OrderItemsGrid from './components/OrderItemsGrid';
import MDTypography from 'components/MDTypography';
import { Card } from '@mui/material';
import SiparisAgaci from 'layouts/aaro/components/Siparis';

const DetailsPage = ({ queries, orderID }) => {
    return (
        <MDBox mb={3}>
            <Card sx={{ mb: 3 }}>
                <MDBox p={3}>
                    <MDBox mb={3}>
                        <MDTypography variant="h6" fontWeight="medium">
                            Sipariş Kalemleri
                        </MDTypography>
                    </MDBox>
                    <OrderItemsGrid queries={queries} orderID={orderID} />
                </MDBox>
            </Card>
            <Card>
                <SiparisAgaci dekontID={orderID} />
            </Card>
        </MDBox>
    );
};

export default DetailsPage;
