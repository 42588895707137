import { useMemo } from 'react';
import { useAaroQuery } from './useAaroQuery';
import { getUrIsEmriPlan } from 'services/outside/aaro/aaroDekont';

export function useProductionQueries(params, options = {}) {
    const { id, type = 'IsEmriID', pageSize = 100, page = 1 } = params;

    const urIsEmriPlanQuery = useAaroQuery(
        ['urIsEmriPlan', id, type, page, pageSize],
        () =>
            getUrIsEmriPlan({
                [type]: id,
                SayfaSatirSayisi: pageSize,
                SayfaNo: page,
            }),
        { ...options, requireSuccess: true }
    );

    return {
        urIsEmriPlanQuery,
    };
}
