import React, { useEffect } from 'react';
import ReactFlow, { MiniMap, Controls, Background, useNodesState, useEdgesState } from 'reactflow';
import dagre from '@dagrejs/dagre';
import 'reactflow/dist/style.css';
import * as d3Format from 'd3-format';

/**
 * -------------------------
 * 1) Yardımcı Format Fonksiyonları
 * -------------------------
 */
function formatCurrency(value) {
    const formatter = d3Format.format(',.2f');
    return `${formatter(value)} ₺`;
}

function formatMinutes(minutesValue) {
    const m = Math.round(minutesValue);
    const hours = Math.floor(m / 60);
    const mins = m % 60;
    if (hours === 0) return `${mins} dk`;
    return `${hours}sa ${mins}dk`;
}

function formatDate(isoDateStr) {
    if (!isoDateStr) return '-';
    const d = new Date(isoDateStr);
    if (isNaN(d.getTime())) return isoDateStr; // Geçersizse ham hali
    return d.toLocaleDateString('tr-TR');
}

/**
 * -------------------------
 * 2) Uretim Planı ve Operasyon Parse
 * -------------------------
 */

/**
 * Tek bir üretim planını parse edip,
 * basit bir node nesnesi döndürüyoruz
 */
function parseUretimPlani(planObj) {
    const planAdi = planObj.OperasyonAdi + ' - ' + (planObj.Tarih || 'Bilinmeyen Plan');
    const planDate = planObj.Tarih ? new Date(planObj.Tarih) : null;

    return {
        name: `Plan: ${planAdi} (Üretilecek)`,
        children: [],
        totalCost: 0,
        totalTime: 0,
        totalQuantity: 0,
        latestDate: planDate,
    };
}

/**
 * Operasyonlar dizisini parse ederek:
 * - Toplam maliyet, süre, miktar
 * - "En" değerleri
 * - Node ağacına uygun children
 */
function parseOperations(operations) {
    let operationsTotalCost = 0;
    let operationsTotalTime = 0;
    let operationsTotalQuantity = 0;
    let latestDate = null;

    let maxCost = 0;
    let maxCostOp = null;
    let maxTime = 0;
    let maxTimeOp = null;
    let maxIsci = 0;
    let maxIsciOp = null;

    const children = operations.map((op) => {
        // Maliyet
        const miktar = parseFloat(op.Miktar) || 0;
        const birimMaliyet = parseFloat(op.BirimMaliyetFiyati) || 0;
        const opCost = miktar * birimMaliyet;

        // Süre (hazırlık + üretim + mola + duraklama)
        const hazirlik = parseFloat(op.HazirlikSure) || 0;
        const uretim = parseFloat(op.UretimSure) || 0;
        const duraklama = parseFloat(op.DuraklamaSure) || 0;
        const mola = parseFloat(op.MolaSure) || 0;
        const opTime = hazirlik + uretim + duraklama + mola;

        // Operasyon bitiş tarihi (en geç tarih bulmak için)
        let opDate = null;
        if (op.Bitis) {
            try {
                opDate = new Date(op.Bitis);
                if (!isNaN(opDate.getTime()) && (!latestDate || opDate > latestDate)) {
                    latestDate = opDate;
                }
            } catch {
                console.warn(`Geçersiz tarih formatı: ${op.Bitis}`);
            }
        }

        operationsTotalCost += opCost;
        operationsTotalTime += opTime;
        operationsTotalQuantity += miktar;

        // "En" değerleri
        if (opCost > maxCost) {
            maxCost = opCost;
            maxCostOp = op;
        }
        if (opTime > maxTime) {
            maxTime = opTime;
            maxTimeOp = op;
        }
        const isci = parseFloat(op.IsciAdedi) || 0;
        if (isci > maxIsci) {
            maxIsci = isci;
            maxIsciOp = op;
        }

        // Label
        const lines = [
            `${op.OperasyonAdi || 'Operasyon'} - ${op.MakineAdi || ''}`,
            `Süre: Haz.${hazirlik}+Ür.${uretim}+Mola.${mola}+Dur.${duraklama} => ${formatMinutes(opTime)}`,
            `Miktar: ${miktar}, BirimMaliyet: ${formatCurrency(birimMaliyet)}, Toplam: ${formatCurrency(opCost)}`,
            `İşçi: ${isci} ${op.Bitis ? ' - Bitis: ' + formatDate(op.Bitis) : ''}`,
        ];

        return {
            name: lines.join('\n'),
            details: {
                type: 'operasyon',
                makineAdi: op.MakineAdi,
                mamulAdi: op.MamulAdi,
                isciAdedi: isci,
                hazirlik,
                uretim,
                duraklama,
                mola,
                opTime,
                toplamMaliyet: opCost,
                bitisTarih: op.Bitis,
            },
            totalCost: opCost,
            totalTime: opTime,
            totalQuantity: miktar,
            latestDate: opDate,
        };
    });

    // Operasyonlar üst düğümü
    const operasyonlarNode = {
        name: 'Operasyonlar',
        children,
        totalCost: operationsTotalCost,
        totalTime: operationsTotalTime,
        totalQuantity: operationsTotalQuantity,
        latestDate,
    };

    if (operationsTotalQuantity > 0) {
        operasyonlarNode.unitCost = operationsTotalCost / operationsTotalQuantity;
        operasyonlarNode.unitTime = operationsTotalTime / operationsTotalQuantity;
    }

    // Operasyonlar node etiketi: toplam/birim masraf & süre + en değerler
    const costText = `ToplamMasraf: ${formatCurrency(operationsTotalCost)}`;
    const costUnitText = operasyonlarNode.unitCost
        ? ` - BirimMaliyet: ${formatCurrency(operasyonlarNode.unitCost)}`
        : '';
    const timeText = operationsTotalTime ? ` - ToplamSüre: ${formatMinutes(operationsTotalTime)}` : '';
    const timeUnitText = operasyonlarNode.unitTime ? ` - BirimSüre: ${formatMinutes(operasyonlarNode.unitTime)}` : '';

    let summaryText = '';
    if (maxCostOp) {
        summaryText += `\nEn Maliyetli: ${maxCostOp.OperasyonAdi} => ${formatCurrency(maxCost)}`;
    }
    if (maxTimeOp) {
        summaryText += `\nEn Uzun Süre: ${maxTimeOp.OperasyonAdi} => ${formatMinutes(maxTime)}`;
    }
    if (maxIsciOp) {
        summaryText += `\nEn Çok İşçi: ${maxIsciOp.OperasyonAdi} => ${maxIsciOp.IsciAdedi}`;
    }
    if (latestDate) {
        summaryText += `\nEn Geç Bitiş: ${formatDate(latestDate.toISOString())}`;
    }

    // Final isim
    operasyonlarNode.name = [
        `Operasyonlar (${operations.length})`,
        `=> ${costText}${costUnitText}${timeText}${timeUnitText}`,
        summaryText,
    ].join('\n');

    return operasyonlarNode;
}

/**
 * -------------------------
 * 3) İş Emri ve Alt İş Emri Parse (REFERANS KALDIRILMIŞ HALİ)
 * -------------------------
 */
function parseIsEmriRecursive(isEmriObj) {
    // Örnek "ReceteAdi" -> "Proje Kapı ...", vs.
    const emriID = isEmriObj.isEmri?.[0]?.ReceteAdi || 'Bilinmeyen İş Emri';

    const operationsCount = Array.isArray(isEmriObj.operasyonlar) ? isEmriObj.operasyonlar.length : 0;
    const plans = Array.isArray(isEmriObj.uretimPlanlari) ? isEmriObj.uretimPlanlari : [];
    const altIsEmirleri = Array.isArray(isEmriObj.altIsEmirleri) ? isEmriObj.altIsEmirleri : [];

    let statusSuffix = '';
    if (operationsCount > 0 && operationsCount === plans.length) {
        statusSuffix = ' (Üretilmiş)';
    } else if (plans.length > 0 && operationsCount === 0) {
        statusSuffix = ' (Üretilecek)';
    }

    const emriName = `İş Emri: ${emriID}${statusSuffix}`;
    const children = [];
    let totalCost = 0;
    let totalTime = 0;
    let totalQuantity = 0;
    let latestDate = null;

    // Operasyonlar
    if (operationsCount > 0) {
        const opsNode = parseOperations(isEmriObj.operasyonlar);
        children.push(opsNode);
        totalCost += opsNode.totalCost;
        totalTime += opsNode.totalTime;
        totalQuantity += opsNode.totalQuantity;
        if (opsNode.latestDate) {
            if (!latestDate || opsNode.latestDate > latestDate) {
                latestDate = opsNode.latestDate;
            }
        }
    }

    // Üretim Planları
    const uretilecekPlanlar = plans.filter((p) => !p.Kapali);
    if (uretilecekPlanlar.length > 0) {
        const planNode = {
            name: `Üretim Planları (${uretilecekPlanlar.length})`,
            children: uretilecekPlanlar.map(parseUretimPlani),
            totalCost: 0,
            totalTime: 0,
            totalQuantity: 0,
            latestDate: null,
        };

        // En geç plan tarihi
        let planMaxDate = null;
        uretilecekPlanlar.forEach((pl) => {
            if (pl.Tarih) {
                try {
                    const d = new Date(pl.Tarih);
                    if (!isNaN(d.getTime()) && (!planMaxDate || d > planMaxDate)) {
                        planMaxDate = d;
                    }
                } catch {
                    console.warn(`Geçersiz plan tarihi: ${pl.Tarih}`);
                }
            }
        });

        if (planMaxDate && (!latestDate || planMaxDate > latestDate)) {
            latestDate = planMaxDate;
            planNode.latestDate = planMaxDate;
        }

        children.push(planNode);
    }

    // Alt İş Emirleri
    if (altIsEmirleri.length > 0) {
        altIsEmirleri.forEach((alt) => {
            const altRes = parseIsEmriRecursive(alt);
            children.push(altRes);

            totalCost += altRes.totalCost;
            totalTime += altRes.totalTime;
            totalQuantity += altRes.totalQuantity;
            if (altRes.latestDate && (!latestDate || altRes.latestDate > latestDate)) {
                latestDate = altRes.latestDate;
            }
        });
    }

    const unitCost = totalQuantity > 0 ? totalCost / totalQuantity : 0;
    const unitTime = totalQuantity > 0 ? totalTime / totalQuantity : 0;

    let emriNameWithCost = emriName;
    if (totalCost > 0) {
        emriNameWithCost += ` - ToplamMaliyet: ${formatCurrency(totalCost)}`;
        if (unitCost > 0) {
            emriNameWithCost += ` - BirimMaliyet: ${formatCurrency(unitCost)}`;
        }
    }
    if (totalTime > 0) {
        emriNameWithCost += ` - ToplamSüre: ${formatMinutes(totalTime)}`;
        if (unitTime > 0) {
            emriNameWithCost += ` - BirimSüre: ${formatMinutes(unitTime)}`;
        }
    }
    if (latestDate) {
        emriNameWithCost += ` - EnGeçTarih: ${formatDate(latestDate.toISOString())}`;
    }

    return {
        name: emriNameWithCost,
        children,
        totalCost,
        totalTime,
        totalQuantity,
        latestDate,
    };
}

/**
 * -------------------------
 * 4) Sipariş Root (transformToHierarchy)
 *  - Karlılık, Toplam Süre, vs.
 *  - "Siparişi takip eden X gün"
 * -------------------------
 */
function transformToHierarchy(sonucData) {
    const rootNode = {
        name: 'Sipariş Root',
        children: [],
        totalCost: 0,
        totalTime: 0,
        totalQuantity: 0,
        totalSales: 0,
        totalProfit: 0,
        latestDate: null,
        orderDate: null,
    };

    if (!Array.isArray(sonucData) || sonucData.length === 0) {
        return rootNode;
    }

    // 1) Tüm stok hareketlerinden en erken Tarih
    let minOrderDate = null;
    sonucData.forEach((item) => {
        const stokDateStr = item?.stokHareketi?.Tarih;
        if (stokDateStr) {
            const d = new Date(stokDateStr);
            if (!isNaN(d.getTime()) && (!minOrderDate || d < minOrderDate)) {
                minOrderDate = d;
            }
        }
    });
    if (minOrderDate) {
        rootNode.orderDate = minOrderDate;
    }

    // 2) Her stok kalemini dolaş
    sonucData.forEach((item) => {
        const stokName = item?.stokHareketi?.KartAdi || 'Bilinmeyen Stok';
        const stokMiktar = parseFloat(item?.stokHareketi?.Miktar) || 0;

        let stokTutar = 0;
        if (item?.stokHareketi?.Tutar) {
            stokTutar = parseFloat(item.stokHareketi.Tutar) || 0;
        } else if (item?.stokHareketi?.BirimFiyatNet) {
            const bf = parseFloat(item.stokHareketi.BirimFiyatNet) || 0;
            stokTutar = bf * stokMiktar;
        } else if (item?.stokHareketi?.TutarDvz) {
            stokTutar = parseFloat(item.stokHareketi.TutarDvz) || 0;
        }

        const stokNode = {
            name: stokName,
            children: [],
            totalCost: 0,
            totalTime: 0,
            totalQuantity: stokMiktar,
            salesAmount: stokTutar,
            latestDate: null,
        };

        // İş Emri parse
        if (item.isEmri) {
            const isEmriNode = parseIsEmriRecursive(item.isEmri);
            stokNode.children.push(isEmriNode);

            stokNode.totalCost += isEmriNode.totalCost;
            stokNode.totalTime += isEmriNode.totalTime;
            if (isEmriNode.latestDate) {
                if (!stokNode.latestDate || isEmriNode.latestDate > stokNode.latestDate) {
                    stokNode.latestDate = isEmriNode.latestDate;
                }
            }
        }

        // Karlılık
        const profit = stokTutar - stokNode.totalCost;
        const unitCost = stokMiktar > 0 ? stokNode.totalCost / stokMiktar : 0;
        const unitProfit = stokMiktar > 0 ? profit / stokMiktar : 0;
        const profitMargin = stokNode.totalCost > 0 ? (profit / stokNode.totalCost) * 100 : 0;

        // Node etiketine ek
        const lines = [
            stokName,
            `SatışGeliri: ${formatCurrency(stokTutar)} (Miktar: ${stokMiktar})`,
            `ÜretimMaliyet: ${formatCurrency(stokNode.totalCost)} (BirimMaliyet: ${formatCurrency(unitCost)})`,
            `Karlılık: ${formatCurrency(profit)} (${profitMargin.toFixed(1)}%) (BirimKâr: ${formatCurrency(
                unitProfit
            )})`,
        ];
        if (stokNode.totalTime > 0) {
            lines.push(`Toplam Üretim Süresi: ${formatMinutes(stokNode.totalTime)}`);
        }
        if (stokNode.latestDate) {
            lines.push(`En Geç Teslim: ${formatDate(stokNode.latestDate.toISOString())}`);
        }

        stokNode.name = lines.join('\n');

        // root'a ekleme
        rootNode.children.push(stokNode);
        rootNode.totalCost += stokNode.totalCost;
        rootNode.totalTime += stokNode.totalTime;
        rootNode.totalQuantity += stokNode.totalQuantity;
        rootNode.totalSales += stokTutar;
        rootNode.totalProfit += profit;

        if (stokNode.latestDate && (!rootNode.latestDate || stokNode.latestDate > rootNode.latestDate)) {
            rootNode.latestDate = stokNode.latestDate;
        }
    });

    // 3) Root etiketi
    const rootLines = [
        `Sipariş Root (${rootNode.children.length} Kalem)`,
        `ToplamSatış: ${formatCurrency(rootNode.totalSales)} - ToplamMaliyet: ${formatCurrency(rootNode.totalCost)}`,
        `ToplamKâr: ${formatCurrency(rootNode.totalProfit)} (${
            rootNode.totalCost > 0 ? ((rootNode.totalProfit / rootNode.totalCost) * 100).toFixed(1) : 0
        }%)`,
    ];

    if (rootNode.latestDate && rootNode.orderDate) {
        const diffMs = rootNode.latestDate.getTime() - rootNode.orderDate.getTime();
        const diffDays = Math.round(diffMs / (1000 * 60 * 60 * 24));
        rootLines.push(
            `En Geç Teslim: ${formatDate(rootNode.latestDate.toISOString())} (Siparişten ${diffDays} gün sonra)`
        );
    } else if (rootNode.latestDate) {
        rootLines.push(`En Geç Teslim: ${formatDate(rootNode.latestDate.toISOString())}`);
    }

    rootNode.name = rootLines.join('\n');
    return rootNode;
}

/**
 * -------------------------
 * 5) ReactFlow Node + Edge + Dagre Layout
 * -------------------------
 */
function getNodeColor(nodeData) {
    const nodeName = nodeData?.name || '';
    const detailsType = nodeData?.details?.type || '';

    // İş Emri
    if (nodeName.startsWith('İş Emri:')) {
        return '#e2725b';
    }
    // Operasyonlar, Planlar
    if (nodeName.startsWith('Operasyonlar')) return '#4caf50';
    if (nodeName.startsWith('Üretim Planları')) return '#ff9800';
    if (detailsType === 'operasyon') return '#81c784';
    // Root
    if (nodeName.startsWith('Sipariş Root')) return '#607d8b';

    // Stok node kârlılık
    if (nodeData.salesAmount !== undefined) {
        const profit = nodeData.salesAmount - (nodeData.totalCost || 0);
        if (profit > 0) return '#4caf50';
        if (profit < 0) return '#f44336';
        return '#ffc107';
    }

    return '#a47864';
}

function buildNodesAndEdgesFromHierarchy(nodeData, parentId = null, idRefObj = { current: 1 }) {
    let nodes = [];
    let edges = [];

    const nodeId = `node-${idRefObj.current++}`;
    const bgColor = getNodeColor(nodeData);

    nodes.push({
        id: nodeId,
        data: {
            label: nodeData.name || 'NoName',
            nodeDetails: nodeData,
        },
        position: { x: 0, y: 0 },
        style: {
            background: bgColor,
            padding: 8,
            borderRadius: 6,
            border: '1px solid #ccc',
            fontSize: '12px',
            color: bgColor === '#4caf50' || bgColor === '#607d8b' ? '#fff' : '#000',
            whiteSpace: 'pre-wrap',
            minWidth: 180,
        },
    });

    if (parentId) {
        edges.push({
            id: `edge-${parentId}-${nodeId}`,
            source: parentId,
            target: nodeId,
            type: 'smoothstep',
            style: {
                strokeWidth: 2,
                stroke: '#555',
            },
        });
    }

    if (Array.isArray(nodeData.children)) {
        nodeData.children.forEach((child) => {
            const { nodes: cNodes, edges: cEdges } = buildNodesAndEdgesFromHierarchy(child, nodeId, idRefObj);
            nodes = [...nodes, ...cNodes];
            edges = [...edges, ...cEdges];
        });
    }

    return { nodes, edges };
}

function applyDagreLayout(initialNodes, initialEdges) {
    const g = new dagre.graphlib.Graph();
    g.setDefaultEdgeLabel(() => ({}));

    g.setGraph({
        rankdir: 'TB',
        ranksep: 300,
        nodesep: 200,
        edgesep: 100,
        marginx: 20,
        marginy: 20,
        ranker: 'tight-tree',
    });

    initialNodes.forEach((node) => {
        const lineCount = node.data.label.split('\n').length;
        const height = Math.max(50, lineCount * 20);
        g.setNode(node.id, { width: 220, height });
    });

    initialEdges.forEach((edge) => {
        g.setEdge(edge.source, edge.target);
    });

    dagre.layout(g);

    const layoutedNodes = initialNodes.map((node) => {
        const n = g.node(node.id);
        node.position = {
            x: n.x - n.width / 2,
            y: n.y - n.height / 2,
        };
        return node;
    });

    return { nodes: layoutedNodes, edges: initialEdges };
}

/**
 * -------------------------
 * 6) Ana React Bileşeni: StockFlowChart
 * -------------------------
 */
export default function StockFlowChart({ data }) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        if (!data || data.length === 0) {
            setNodes([]);
            setEdges([]);
            return;
        }

        try {
            // 1) Hierarchy oluştur
            const root = transformToHierarchy(data);

            // 2) Node-Edge
            const { nodes: unlayoutedNodes, edges: unlayoutedEdges } = buildNodesAndEdgesFromHierarchy(root);

            // 3) Dagre Layout
            const { nodes: layoutedNodes, edges: layoutedEdges } = applyDagreLayout(unlayoutedNodes, unlayoutedEdges);

            setNodes(layoutedNodes);
            setEdges(layoutedEdges);
        } catch (error) {
            console.error('Ağaç oluşturma hatası:', error);
            setNodes([
                {
                    id: 'error-node',
                    data: { label: `Veri işlenirken hata oluştu: ${error.message}` },
                    position: { x: 0, y: 0 },
                    style: { background: '#ffcdd2', color: '#b71c1c', padding: 10 },
                },
            ]);
            setEdges([]);
        }
    }, [data]);

    return (
        <div style={{ width: '100%', height: '800px' }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                fitView
                fitViewOptions={{
                    padding: 0.5,
                    minZoom: 0.01,
                    maxZoom: 4,
                    includeHiddenNodes: true,
                }}
                minZoom={0.01}
                maxZoom={4}
                defaultViewport={{ zoom: 0.05 }}
            >
                <Background />
                <Controls showZoom showFitView fitViewOptions={{ padding: 0.5 }} />
                <MiniMap style={{ background: '#f0f0f0' }} zoomable pannable maskColor="rgba(0, 0, 0, 0.2)" />
            </ReactFlow>
        </div>
    );
}
