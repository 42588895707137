import React, { memo } from 'react';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import MDTypography from 'components/MDTypography';
import { formatPrice } from 'helpers/priceHelpers';
import { useProductionQueries } from 'hooks/aaro/useProductionQueries';

const GridRowDetailedSummary = memo(({ row }) => {
    const { urIsEmriPlanQuery } = useProductionQueries({ id: row.IsEmriID });

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        return new Date(dateString).toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const formatVardiya = (vardiya) => {
        if (vardiya === undefined || vardiya === null) return '-';
        const vardiyalar = {
            0: 'Gündüz',
            1: 'Akşam',
            2: 'Gece',
        };
        return vardiyalar[vardiya] || vardiya;
    };

    const formatSure = (dakika) => {
        if (!dakika && dakika !== 0) return '-';
        const saat = Math.floor(dakika / 60);
        const kalanDakika = dakika % 60;

        if (saat > 0 && kalanDakika > 0) {
            return `${saat} saat ${kalanDakika} dakika`;
        } else if (saat > 0) {
            return `${saat} saat`;
        } else {
            return `${kalanDakika} dakika`;
        }
    };

    // IsEmriPlan verilerini hazırla
    const details = urIsEmriPlanQuery?.data
        ? [...new Set(urIsEmriPlanQuery.data)]
              .map((plan) => ({
                  id: `plan-${plan.IsEmriID}-${plan.OperasyonSirasi}-${plan.OperasyonKodu}`,
                  field: `${plan.OperasyonSirasi}. ${plan.OperasyonAdi}`,
                  value: `${formatDate(plan.Tarih)} tarihinde ${formatVardiya(plan.Vardiya)} vardiyasında ${
                      plan.MakineAdi
                  }'da ${formatSure(plan.Sure)}'da ${plan.Miktar.toLocaleString('tr-TR')} ${
                      plan.Miktar === 1 ? 'adet' : 'adet'
                  } ${plan.OperasyonAdi.toLowerCase()} işlemi yapılacaktır.`,
                  date: new Date(plan.Tarih), // Sıralama için tarih ekle
              }))
              .sort((a, b) => a.date - b.date) // Önce tarihe göre sırala
        : [];

    // Benzersiz kayıtları filtrele
    const uniqueDetails = details
        .filter((detail, index, self) => index === self.findIndex((d) => d.field === detail.field))
        .map(({ id, field, value }) => ({ id, field, value })); // date alanını çıkar

    return (
        <Box sx={{ p: 2, backgroundColor: '#f5f5f5' }}>
            <DataGridPro
                rows={uniqueDetails}
                columns={[
                    {
                        field: 'field',
                        headerName: 'Operasyon',
                        flex: 1,
                        renderCell: (params) => (
                            <MDTypography variant="body2" fontWeight="medium">
                                {params.value}
                            </MDTypography>
                        ),
                    },
                    {
                        field: 'value',
                        headerName: 'Planlama Detayı',
                        flex: 2,
                        renderCell: (params) => <MDTypography variant="body2">{params.value}</MDTypography>,
                    },
                ]}
                hideFooter
                autoHeight
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableSelectionOnClick
                sx={{
                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                        padding: '8px',
                    },
                    '& .MuiDataGrid-row': {
                        '&:nth-of-type(odd)': {
                            backgroundColor: 'rgba(0, 0, 0, 0.02)',
                        },
                    },
                    border: 'none',
                    borderRadius: 1,
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    },
                }}
            />
        </Box>
    );
});

export default GridRowDetailedSummary;
